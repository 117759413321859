<template>
    <div class="menucontainer">
        <div class="sitethumb">
            <div v-if="!collapsedstate.state" class="sitethumbtext noselect nositeicon">
                <div class="shorttitle">{{ $langsmgr('langkey.sitename.short') }}</div>
            </div>
            <div v-else class="sitethumbicon" />
        </div>
        <a-menu id="sidemenus" mode="inline"
            v-model:selectedKeys="selectedKeys"
            class="menuitems"
            :items="items" @click="handleClick"></a-menu>
        <div class="pmcopyright" v-if="!collapsedstate.state">
            <a class="pmcopylink" :href="$siteurl">{{ $langsmgr('langkey.sitename') }}</a>
            <span>{{$siteversion}}</span>
        </div>
        <div class="pmcopycenter" v-else>
            <a class="pmcopylink" :href="$siteurl">{{ $langsmgr('langkey.sitename.short') }}</a>
            <span>{{$siteversion}}</span>
        </div>
    </div>
</template>

<script setup>
import { UserOutlined,DollarOutlined,FileDoneOutlined,AreaChartOutlined,GlobalOutlined,GiftOutlined,TagsFilled,DesktopOutlined,FileProtectOutlined,UsergroupAddOutlined,ApartmentOutlined } from '@ant-design/icons-vue'
import {reactive,ref,h,defineProps, watchEffect,getCurrentInstance} from 'vue'

const collapsedstate = reactive({
    state: false
})
const props = defineProps(['selectedkey', 'collapsed'])
const selectedKeys =  ref([props.selectedkey?props.selectedkey:'1'])
const globalinstance = getCurrentInstance()
const menumap = {
    "1": "#/"
}

watchEffect(()=>{
    collapsedstate.state = props.collapsed
})

const collapsed = ref(props.collapsed?props.collapsed:false)

function getItem(label, key, icon, children, type) {
    return { key, icon, children, label, type }
}

const items = reactive([
    getItem(globalinstance.appContext.config.globalProperties.$langsmgr("langkey.menuitem.dashboard"),'3', () => h(DesktopOutlined)),
    getItem(globalinstance.appContext.config.globalProperties.$langsmgr("langkey.menuitem.document"),'15', () => h(FileProtectOutlined)),

    getItem(globalinstance.appContext.config.globalProperties.$langsmgr("langkey.menugroup.service"), 'nsm', null, [
        {type: 'divider'},
        getItem(globalinstance.appContext.config.globalProperties.$langsmgr("langkey.menuitem.services"), '9', () => h(GlobalOutlined)),
        getItem(globalinstance.appContext.config.globalProperties.$langsmgr("langkey.menuitem.myroutes"), '17', () => h(ApartmentOutlined)),
        getItem(globalinstance.appContext.config.globalProperties.$langsmgr("langkey.menuitem.packages"), '10', () => h(GiftOutlined))
    ], 'group'),
    
    getItem(globalinstance.appContext.config.globalProperties.$langsmgr("langkey.menugroup.finance"), 'fsm', null, [
        {type: 'divider'},
        getItem(globalinstance.appContext.config.globalProperties.$langsmgr("langkey.menuitem.billings"), '5', () => h(FileDoneOutlined)),
        getItem(globalinstance.appContext.config.globalProperties.$langsmgr("langkey.menuitem.affplan"), '16', () => h(UsergroupAddOutlined))
    ], 'group'),
    
    getItem(globalinstance.appContext.config.globalProperties.$langsmgr("langkey.menugroup.account"), 'asm', null, [
        {type: 'divider'},
        getItem(globalinstance.appContext.config.globalProperties.$langsmgr("langkey.menuitem.profile"), '4', () => h(UserOutlined)),
        getItem(globalinstance.appContext.config.globalProperties.$langsmgr("langkey.menuitem.tickets"), '13', () => h(TagsFilled)),
        //getItem(globalinstance.appContext.config.globalProperties.$langsmgr("langkey.menuitem.finance"), '7', () => h(DollarOutlined)),
        getItem(globalinstance.appContext.config.globalProperties.$langsmgr("langkey.menuitem.flowstats"), '8', () => h(AreaChartOutlined)),
    ], 'group'),

    //getItem(globalinstance.appContext.config.globalProperties.$langsmgr("langkey.menugroup.custom"), 'csm', null, [
    //    {type: 'divider'},
    //], 'group')
])
const handleClick = e => {
    globalinstance.appContext.config.globalProperties.$uimenuselectedkey = e.key
    location.href='#'+globalinstance.appContext.config.globalProperties.$menumappaths[e.key];
}


</script>


<style scoped>
:deep(.ant-menu-item-group){
    padding-top:20px;
}


.sitethumb{
    width:100%;
    text-align: center;
    height:64px;
    background-color:#1b508c;
    overflow:hidden;
}
.shorttitle{
    font-size: 20px;
    width:100%;
    color:#fff;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: normal;
}
.shortsubtitle{
    margin:5px 0 0 0;
    font-size: 12px;
    line-height: 20px;
    font-weight: normal;
    color:white;
}
.nositeicon{
    margin-left: 20px;
}
.sitethumbicon{
    float:left;
    display: block;
    height: 48px;
    width: 48px;
    margin:5px 0 0 15px;
    background-image:url(./../assets/logo.png);
    background-size:48px 48px;
}
.sitethumbtext{
    float:left;
    text-align:left;
    line-height: 65px;
    padding-left: 20px;
    text-align: center;
    font-family: 'Microsoft YaHei UI';
}
.menucontainer{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
}
.menuitems {
    background-color:#ffffff;
    color:#666666;
    padding:20px 10px 0 10px;
    text-align: left;
}
.noselect{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.pmcopyright {
    position: fixed;
    z-index: 3;
    left: 15px;
    bottom: 15px;
    font-size: 11px;
    color: #cccccc;
    text-align: left;
}
.pmcopycenter {
    position: fixed;
    z-index: 3;
    width:55px;
    left: 15px;
    bottom: 15px;
    font-size: 11px;
    color: #cccccc;
    text-align: center;
}
.pmcopylink{
    color:#cccccc;
    text-decoration: none;
    padding-right: 10px;
}
.pmcopylink:hover{
    color:#999999;
    text-decoration: underline;
}
</style>